import { FC, useState, useCallback, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { Topbar, SelectInputField } from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector, useDispatchOnMount } from "hooks";
import {
  getAlarmsByQueryThunk,
  getObjectsByQueryThunk,
  postUnlockAllAlarmsThunk,
  clearObjectData,
} from "app/features";
import {
  selectAlarmDeskState,
  selectQueriedObjectsState,
} from "app/data/selectors";
import AlarmDeskTableGrid from "components/common/AlarmDeskTableGrid";
import { SearchFieldWrapper } from "components/common/Map/Map.styled";
import { isEmpty } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { alarmParams } from "app/data/constants";
import { useTranslation } from "react-i18next";

const AlarmDesk: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Global State
  const { loading, alarms } = useAppSelector(selectAlarmDeskState);

  const { objects, loading: objectsLoading } = useAppSelector(
    selectQueriedObjectsState
  );

  // Local State
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    dispatch(getAlarmsByQueryThunk(alarmParams));

    onClearText();

    return () => {
      dispatch(postUnlockAllAlarmsThunk());
    };
  }, []);

  const debouncedHandleOnChange = useDebouncedCallback(() => {
    if (isEmpty(searchText)) return;
    dispatch(
      getObjectsByQueryThunk({
        itemsPerPage: 10,
        page: 1,
        where: [{ field: "name", value: searchText, type: 0 }],
      })
    );
  }, 300);

  const handleChange = useCallback(
    (value: string) => {
      setSearchText(value);
      debouncedHandleOnChange();
    },
    [debouncedHandleOnChange]
  );

  const onDropDownSelect = (id: string) =>
    id ? navigate("/dashboard/alarm-history/" + id) : null;

  const onClearText = () => {
    dispatch(clearObjectData());
  };

  return (
    <Box>
      <Topbar theme={theme} title={t("alarmDesk.title")}>
        <SearchFieldWrapper>
          <SelectInputField
            loading={objectsLoading}
            name="search"
            placeholder={t("alarmDesk.labels.search")}
            options={objects}
            optionLabelKey={"name"}
            optionValueKey={"uniqueId"}
            onSearchTextChange={handleChange}
            onChange={onDropDownSelect}
            searchable
            emptyOptionsText={"Type to search..."}
            onClearText={onClearText}
            onBlur={onClearText}
          />
        </SearchFieldWrapper>
      </Topbar>
      <AlarmDeskTableGrid data={alarms} loading={loading} fromDashboard />
    </Box>
  );
};

export default AlarmDesk;
