import { FC, useMemo, useState, useEffect, useCallback } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import {
  Button,
  DataCardAccordionLayout,
  KeyValueTable,
  types,
} from "@vilocnv/allsetra-core";
import { AddCircleOutlined } from "@mui/icons-material";

// DATA
import {
  countryWhiteListThunk,
  getActiveAlarmConfig,
  getAllSubscriptionsByObjectIdThunk,
  useGetAlarmPersonQsMutation,
  useGetAlarmPersonsForAlarmQuery,
} from "app/features";
import {
  transformAlarmForObjectInfoTable,
  transformAlarmPersonsForTable,
  transformOwnerForCompanyInformation,
  transformOwnerCountriesForWhitelisted,
  countryWhiteListValidationSchema,
  countryWhitelistInitialValues,
  transfromAlarmConfigForTable,
  transfromWorkingHrsForTable,
} from "app/data/helpers";
import CountryWhiteListModal from "./CountryWhiteListModal";
import CountryDeleteModal from "./CountryDeleteModal";
import { Formik, Form } from "formik";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectAlarmDeskState,
  selectObjectSubscriptions,
} from "app/data/selectors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface AlarmMetadataProps {
  data: types.IAlarm;
}

const AlarmMetadata: FC<AlarmMetadataProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Global State
  const { countryListLoading, activeAlarmConfigLoading, activeAlarmConfig } =
    useAppSelector(selectAlarmDeskState);

  const { objectSubscriptions } = useAppSelector(selectObjectSubscriptions);

  // Local State
  const [countryModal, setCountryModal] = useState<boolean>(false);
  const [countryDeleteModal, setCountryDeleteModal] = useState<boolean>(false);
  const [countryName, setCountryName] = useState<string>("");
  const [activePerson, setActivePerson] = useState<number | null>(null);

  const { data: alarmPersonsData } = useGetAlarmPersonsForAlarmQuery(
    data.uniqueId
  );

  const [getAlarmPersonQs, { isLoading, data: alarmPersonQsData }] =
    useGetAlarmPersonQsMutation();

  useEffect(() => {
    dispatch(getAllSubscriptionsByObjectIdThunk(data?.object?.uniqueId));
  }, [data?.object?.uniqueId]);

  const handleAlarmPersonClick = async (person: any, index: number) => {
    setActivePerson(index);
    await getAlarmPersonQs(person.uniqueId);
  };

  const handleDeleteCountryClick = (name: string) => {
    setCountryName(name);
    setCountryDeleteModal(true);
  };

  const handleDeleteCountry = async () => {
    const countries: any = [];
    data.object?.alarmOwner?.countries.map((item: any) => {
      if (item.name !== countryName) countries.push(item.id);
    });
    const accountId = data?.object?.alarmOwner?.uniqueId || "";
    const values = { countries };
    const { type } = await dispatch(
      countryWhiteListThunk({ accountId, data: { values } })
    );

    if (type === "alarmDesk/CountryWhiteListThunk/fulfilled") {
      onClose();
    }
  };

  const handleDeviceClick = useCallback((deviceId: string) => {
    navigate("/dashboard/devices/" + deviceId);
  }, []);

  const handleObjectClick = useCallback((objectId: string) => {
    navigate("/dashboard/objects/" + objectId);
  }, []);

  // Data for Components
  const {
    objectInformation,
    companyInformation,
    countriesWhiteListed,
    alarmConfig,
    workingHrs,
  } = useMemo(() => {
    return {
      alarmConfig: transfromAlarmConfigForTable(activeAlarmConfig),
      workingHrs: transfromWorkingHrsForTable(data?.object),
      objectInformation: transformAlarmForObjectInfoTable(
        data,
        handleDeviceClick,
        handleObjectClick,
        objectSubscriptions
      ),
      companyInformation: transformOwnerForCompanyInformation(data?.object),
      countriesWhiteListed: transformOwnerCountriesForWhitelisted(
        data?.object,
        handleDeleteCountryClick
      ),
    };
  }, [data, activeAlarmConfig]);

  const { alarmPersons } = useMemo(() => {
    return {
      alarmPersons: transformAlarmPersonsForTable(
        alarmPersonsData ?? [],
        handleAlarmPersonClick,
        activePerson,
        alarmPersonQsData,
        isLoading
      ),
    };
  }, [alarmPersonsData, activePerson, alarmPersonQsData, isLoading]);

  const onSubmitHandler = async (values: any, formikHelpers: any) => {
    const accountId = data?.object?.alarmOwner?.uniqueId || "";
    const { type } = await dispatch(
      countryWhiteListThunk({ accountId, data: { values } })
    );

    if (type === "alarmDesk/CountryWhiteListThunk/fulfilled") {
      onClose();
      formikHelpers.resetForm();
    }
  };

  const onClose = () => {
    setCountryModal(false);
    setCountryDeleteModal(false);
  };

  const getInitialValues = () => {
    if (countriesWhiteListed.ids.length > 0) {
      return { countries: countriesWhiteListed.ids };
    } else {
      return countryWhitelistInitialValues;
    }
  };

  const handleAlarmsConfigToggle = (isOpen: boolean) => {
    if (isOpen) {
      dispatch(
        getActiveAlarmConfig({
          objectId: data?.object.uniqueId,
        })
      );
    }
  };

  return (
    <Box my={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DataCardAccordionLayout
            title={t("alarmDesk.labels.objectInformation")}
            expand={true}
          >
            {/* @ts-ignore */}
            <KeyValueTable fullStretched records={objectInformation} />
          </DataCardAccordionLayout>
        </Grid>
        <Grid item xs={12} md={6}>
          <DataCardAccordionLayout
            title={t("alarmDesk.labels.alarmPersons")}
            expand={true}
          >
            <>
              {isLoading ? <CircularProgress size={25} /> : null}
              <KeyValueTable fullStretched records={alarmPersons} />
            </>
          </DataCardAccordionLayout>
        </Grid>
        <Grid item xs={12} md={6}>
          <DataCardAccordionLayout
            title={t("alarmDesk.labels.companyInformation")}
            expand={true}
          >
            <KeyValueTable fullStretched records={companyInformation} />
          </DataCardAccordionLayout>
        </Grid>
        <Grid item xs={12} md={6}>
          <DataCardAccordionLayout
            title={t("alarmDesk.labels.countryWhitelist")}
            expand={true}
          >
            <Button
              endIcon={<AddCircleOutlined />}
              variant={"text"}
              size={"small"}
              onClick={() => setCountryModal(true)}
            >
              Add a new country
            </Button>
            <KeyValueTable
              fullStretched
              records={countriesWhiteListed?.data || {}}
            />
          </DataCardAccordionLayout>
        </Grid>
        <Grid item xs={12} md={6}>
          <DataCardAccordionLayout
            title={t("alarmDesk.labels.workingHours")}
            expand={false}
            showCustomBadge={data?.object?.isWorkingHoursOverriden || false}
          >
            <KeyValueTable fullStretched records={workingHrs} />
          </DataCardAccordionLayout>
        </Grid>
        <Grid item xs={12} md={6}>
          <DataCardAccordionLayout
            title={t("alarmDesk.labels.alarmsConfiguration")}
            onToggle={handleAlarmsConfigToggle}
            expand={false}
            showWarningIcon={data?.awayModeEnabled}
            showCustomBadge={
              activeAlarmConfig?.objectHasCustomAlarmConfiguration || false
            }
          >
            {activeAlarmConfigLoading ? (
              <CircularProgress size={20} />
            ) : (
              <KeyValueTable fullStretched records={alarmConfig} />
            )}
          </DataCardAccordionLayout>
        </Grid>
      </Grid>
      <Formik
        initialValues={getInitialValues()}
        validationSchema={countryWhiteListValidationSchema}
        onSubmit={onSubmitHandler}
        enableReinitialize
        validateOnMount
      >
        <Form>
          <CountryWhiteListModal open={countryModal} onClose={onClose} />
        </Form>
      </Formik>
      <CountryDeleteModal
        countryName={countryName}
        open={countryDeleteModal}
        onClose={onClose}
        handleSubmit={handleDeleteCountry}
        isSubmitting={countryListLoading}
      />
    </Box>
  );
};

export default AlarmMetadata;
